<template>
  <div class="loginForm">
    <base-alerts></base-alerts>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(userLogin)">
        <base-input
          className="userName"
          placeholder="Email"
          type="email"
          rules="required|email"
          v-model="loginDetails.email"
          @input="userInput"
          name="Email"
        />
        <base-input
          className="password"
          :placeholder="$t('global.Password')"
          type="password"
          rules="required"
          v-model="loginDetails.password"
          @input="passwordInput"
          name="Password"
        />
        <div class="button-row">
          <button
            type="submit"
            to="/user"
            :class="processing ? 'processing' : ''"
            class="btn btn-primary large"
            :style="{width: '47%', marginRight: '12px'}"
          >
            {{$t('global.Login to Continue')}}
          </button>
          <!-- [29 Jun 2022] HR - Added to display buttons in same area -->
          <!-- <router-link
            :to="$i18nRoute({name: 'userRegistration'})"
            class="btn btn-primary large"
            :style="{width: '47%'}"
          >
            {{$t('global.Register')}}
          </router-link> -->
          <router-link
            :to="checkUserType == true ? $i18nRoute({name: 'userRegistration'}) : $i18nRoute({name: 'brandRegistration'})"
            class="btn btn-primary large"
            :style="{width: '47%'}"
          >
            {{$t('global.Register')}}
          </router-link>
        </div>
        <!-- <div class="or"><span>or</span></div> -->
      </b-form>
    </validation-observer>
    <div class="line-brder" v-if="checkUserType">
        <span>{{$t('Or sign in with')}}</span>
    </div>
    <div class="social-links-blcok" v-if="checkUserType">
      <a href="#" class="facebook-signup" @click.prevent="connectFacebook">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="#3578E5"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/></svg>
        Facebook
      </a>
      <!--<a href="#" class="google-signup" @click.prevent="loginWithGoogle">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" aria-hidden="true"><title>Google</title><g fill="none" fill-rule="evenodd"><path fill="#4285F4" d="M17.64 9.2045c0-.6381-.0573-1.2518-.1636-1.8409H9v3.4814h4.8436c-.2086 1.125-.8427 2.0782-1.7959 2.7164v2.2581h2.9087c1.7018-1.5668 2.6836-3.874 2.6836-6.615z"></path><path fill="#34A853" d="M9 18c2.43 0 4.4673-.806 5.9564-2.1805l-2.9087-2.2581c-.8059.54-1.8368.859-3.0477.859-2.344 0-4.3282-1.5831-5.036-3.7104H.9574v2.3318C2.4382 15.9832 5.4818 18 9 18z"></path><path fill="#FBBC05" d="M3.964 10.71c-.18-.54-.2822-1.1168-.2822-1.71s.1023-1.17.2823-1.71V4.9582H.9573A8.9965 8.9965 0 0 0 0 9c0 1.4523.3477 2.8268.9573 4.0418L3.964 10.71z"></path><path fill="#EA4335" d="M9 3.5795c1.3214 0 2.5077.4541 3.4405 1.346l2.5813-2.5814C13.4632.8918 11.426 0 9 0 5.4818 0 2.4382 2.0168.9573 4.9582L3.964 7.29C4.6718 5.1627 6.6559 3.5795 9 3.5795z"></path></g></svg>
            Google
        </a>-->
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { CHECK_USERNAME, CHECK_SOCIAL_ACCOUNT } from "@/graphql/common/query";
import { SOCIAL_LOGIN_ACCOUNT } from "@/graphql/user/mutations";//HR 
import { onLogin, apolloClient } from "@/vue-apollo";
import { initFbsdk } from '@/plugins/facebook_oAuth.js'
import { Trans } from "@/plugins/Translation";
export default {  
  data() {
    return {
      loginDetails: {
        email: "",
        password: "",
      },
      processing: false,
      checkUserType: false,
      socialAccount: { //HR
        social_access_token: null,
        social_login_id: null,
        social_login_type: null,
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  mounted () {
    initFbsdk();
    /*Temp check HR*/
    this.checkUserType = this.$route.name === "brandloginOrRegister" ? false : true;
  },
  methods: {
    ...mapActions(["login", "logOut"]),
    async userLogin() {
      this.processing = true;
      this.logOut();
      await this.login(this.loginDetails).catch((e) => {
        this.processing = false;
      });
    },
    userInput(data) {
      this.loginDetails.email = data;
    },
    passwordInput(data) {
      this.loginDetails.password = data;
    },
    async connectFacebook() {
      
      let facebookData = null;
      await new Promise((resolve, reject) => {FB.login(function(response) {
          // handle the response
          facebookData = response;
          resolve();
          return facebookData;
      }, {
          scope: 'email', 
          return_scopes: true
      })});

      const data = await this.$apollo.query({
          query: CHECK_SOCIAL_ACCOUNT,
          variables: {
            socialUserID: facebookData.authResponse.userID,
          },
      });
      
      if (data.data.socialAccountAvailable.msg !== "user does not exist") {
        
        this.socialAccount.social_access_token = facebookData.authResponse.accessToken;
        this.socialAccount.social_login_id = facebookData.authResponse.userID;
        this.socialAccount.social_login_type = 'facebook';

        await this.$apollo
        .mutate({
          mutation: SOCIAL_LOGIN_ACCOUNT,
          variables: this.socialAccount,
        })
        .then((data) => {
          if (data) {
            const token = JSON.stringify(data.data.updateSocialLoginAccount.token);
            this.$store.commit('SET_TOKEN',token);
            onLogin(apolloClient, token).then(() => {
              this.$store.dispatch("setUser").then(() => {
                this.$router.push(this.$i18nRoute({name: 'userDashboard'}));
                // this.$i18nRoute({name: 'User'});
              });
            });
            // this.$router.push('/user');
          }
        })
        .catch((e) => {
          this.showMediaConnectButtons = true;
          this.isDataAddedUpdated = false;
          this.handleError(e);
        });
      } else {
        FB.logout(function(response) {/*user is now logged out*/});
        this.notify("error", "User is not registered. Please register the user first.");
      }
    },
    loginWithGoogle() {
      this.$gAuth
        .signIn()
        .then(GoogleUser => {
          // on success do something
          console.log('GoogleUser', GoogleUser)
          console.log('getId', GoogleUser.getId())
          console.log('basicprofile', GoogleUser.getBasicProfile().getName())
          console.log('getBasicProfile', GoogleUser.getBasicProfile())
          console.log('getAuthResponse', GoogleUser.getAuthResponse())
          var userInfo = {
            loginType: 'google',
            google: {
              auth: GoogleUser.getAuthResponse(),
              user: {
                name: GoogleUser.getBasicProfile().getName(),
                email: GoogleUser.getBasicProfile().getEmail(),
                profileImage: GoogleUser.getBasicProfile().getImageUrl()
              }
            }
          }
          this.$store.commit('setLoginUser', userInfo)
          //router.push('/home')
        })
        .catch(error => {
          console.log('error', error)
        })
    },
  },
  watch: {
    user() {
      const size = Object.keys(this.user).length;
      const currentLang = Trans.getUserLang().langNoISO;
      // console.log(Trans.)
      if (size != 0) {
        if (this.user.type == 0) {
          // this.$router.push("/user");
          this.$router.push(this.$i18nRoute({name: 'userDashboard'}));
        } else {
          // this.$router.push("/brand");
          this.$router.push(this.$i18nRoute({name: 'brandDashboard'}));
        }
      }
    },
    // "$store.state.alert.message"() {
    //   this.alert.message == null
    //     ? (this.processing = true)
    //     : (this.processing = false);
    // },
  },
};
</script>

<style lang="scss" scoped>
.button-row {
  @include flex(center, center);
}
</style>

<style lang="scss">
form {
  max-width: 440px;
  margin: 0;
}
.button-row {
  margin-top: rem(40px);
  // @include flex(center, center);
  @media screen and (max-width: 767px) {
    margin-top: rem(25px);
  }
  .btn{
    margin:10px 0px;
  }
}
.form-group {
  margin-bottom: rem(25px);
  @media screen and (max-width: 767px) {
    margin-bottom: rem(16px);
  }
}
.LoginRegister{
@media screen and (max-width:767px){
     .contentWrapper{
        align-items:initial !important;
      }
  }
}
.facebook-signup {
    color: #031b4e;
    background: #f2f8ff;
    border: 1px solid rgba(0,105,255,.2);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 3px;
    display: inline-block;
    /*display: none;*/
    margin-top: 0;
    width: 47.5%;
    padding: 15px;
    text-align: center;
    position: inherit;
    margin:10px 15px 10px 0px;

}
.google-signup {
    color: #031b4e;
    background: #f2f8ff;
    border: 1px solid rgba(0,105,255,.2);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 3px;
    /*display: inline-block;*/
    display: none;
    margin-top: 0;
    width: 47.5%;
    padding: 15px;
    text-align: center;
    position: inherit;
}
.loginForm{
  .social-links-blcok{
    display:flex;
    align-items:center;
    justify-content:center;
  }
  .facebook, .google{
    &-signup{
      color:#000;
      &:hover
      {
        color:#000;
      }
    }
  }
}
.line-brder{
    position:relative;
    display:flex;
    align-items:center;
    justify-content:center;
    margin: 20px 0px;
    &:before{
         content: "";
        width: 100%;
        height: 1px;
        background: rgba(100, 100, 100, 0.5);
        position: absolute;
        left: 0px;
        right: 0px;     
    }
    span{
      position:absolute;
      padding:0px 10px;
      background:#f3f2f6;
      color:#000;
    }
  }
</style>
